import React from 'react';
import { useLocation } from 'react-router-dom';
import Routes from './routes';
import Navbar from './navbar/NavBar';
import CreateHi5Form from './createHi5/CreateHi5Form';
import './App.scss';

export default function App() {
    const location = useLocation();
    return (
        <div className="App">
            <div className="NavBar">{location.pathname !== '/' && <Navbar />}</div>
            <div className="PageContent">
                <Routes />
            </div>
            <div className={location.pathname !== '/' ? 'CreateHi5Form' : 'CreateHi5FormSignIn'}>
                {location.pathname !== '/' && <CreateHi5Form />}
            </div>
        </div>
    );
}
