import React from 'react';

import './Textbox.scss';

export default function Textbox(props) {
    const { label, placeholder, onChange, name, error, value, id } = props;
    return (
        <div className="Textbox">
            <label htmlFor={id}>{label}</label>

            <textarea
                onChange={onChange}
                name={name}
                id={id}
                type="text"
                placeholder={placeholder}
                value={value || ''}
            />
            <span className="Textbox__error">{error} &nbsp;</span>
        </div>
    );
}
