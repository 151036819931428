import React, { useState } from 'react';

import './commentCard.scss';
import Auth from 'auth.service';
import SendCommentIcon from '@material-ui/icons/Navigation';
import { saveCommentToDB } from '../../api/Hi5Api';
// Import get username, profilePic, Need to pass in current ID

export default function CreateCommentForm(props) {
    const { h5ID } = props;
    const [comment, setComment] = useState({
        h5ID,
        username: Auth.getUsername(),
        profilePic: Auth.getProfileImg(),
        comment: '',
    });

    function handleChange(event) {
        const commentTextValue = event.target.value;

        setComment(prevComment => {
            return {
                ...prevComment,
                comment: commentTextValue,
            };
        });
    }

    function passCommentToParent(commentData) {
        props.onSubmit(commentData);
    }

    function saveComment(e) {
        // create comment with values for table
        e.preventDefault();
        saveCommentToDB(comment);
        passCommentToParent(comment);
        setComment(prevComment => {
            return {
                ...prevComment,
                comment: '',
            };
        });
    }

    return (
        <div className="CreateCommentForm">
            <form>
                <textarea
                    className="CommentTextEntry"
                    onChange={handleChange}
                    name="comment"
                    type="text"
                    placeholder="enter comment here"
                    value={comment.comment}
                />
                <button
                    data-testid="submit"
                    className="SubmitCommentButton"
                    type="submit"
                    onClick={saveComment}
                >
                    <SendCommentIcon />
                </button>
            </form>
        </div>
    );
}
