import Endpoints from './ApiEndpoints';

// Function to save Hi5 when submitted POST

export async function postData(url, data = {}) {
    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
        return error;
    }
}

export async function getAllH5() {
    const url = `${Endpoints.GetH5}`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching the Hi Fives, sorry about that.',
                error: true,
            };
        }

        const result = await response.json();
        // console.log(result);
        return result;
    } catch (e) {
        return e;
    }
}

export async function getLikedH5(username) {
    const url = `${Endpoints.GetH5}/${username}/liked`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching the Hi Fives, sorry about that.',
                error: true,
            };
        }

        const result = await response.json();
        // console.log(result);
        return result;
    } catch (e) {
        return e;
    }
}

export async function getReceivedH5(username) {
    const url = `${Endpoints.GetH5}/${username}/received`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching the Hi Fives, sorry about that.',
                error: true,
            };
        }

        const result = await response.json();
        // console.log(result);
        return result;
    } catch (e) {
        return e;
    }
}

export async function getSentH5(username) {
    const url = `${Endpoints.GetH5}/${username}/sent`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching the Hi Fives, sorry about that.',
                error: true,
            };
        }

        const result = await response.json();
        // console.log(result);
        return result;
    } catch (e) {
        return e;
    }
}

export async function saveH5(hi5) {
    const url = `${Endpoints.SaveH5}`;
    try {
        const result = await postData(url, { hi5 });
        return result;
    } catch (e) {
        return e;
    }
}

export async function addLike(id, username) {
    const url = `${Endpoints.SaveH5}/${id}/like/${username}`;
    try {
        const result = await postData(url, { id });
        return result;
    } catch (e) {
        return e;
    }
}

export async function saveCommentToDB(comment) {
    const url = `${Endpoints.SaveH5}/${comment.h5ID}/comment`;
    try {
        const result = await postData(url, { comment });
        return result;
    } catch (e) {
        return e;
    }
}

export async function getH5Comments(id) {
    const url = `${Endpoints.SaveH5}/${id}/comments`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching the Hi Fives, sorry about that.',
                error: true,
            };
        }

        const result = await response.json();
        // console.log(result);
        return result;
    } catch (e) {
        return e;
    }
}

export async function initializeUserInfo(username, profilePic) {
    const url = Endpoints.InitializeUser;
    try {
        const result = await postData(url, { username, profilePic });
        return result;
    } catch (e) {
        return e;
    }
}
