import React from 'react';
import './gifPage.scss';

export default function Gif() {
    return (
        <div className="Gif">
            <h1 className="Text">Oh No You Didn&apos;t!!!</h1>
            <iframe
                title="Shame"
                src="https://giphy.com/embed/ACIITGzLTZUUE"
                width="600"
                height="430"
                frameBorder="0"
                className="Giphy"
                allowFullScreen="true"
            />
        </div>
    );
}
