import React, { useEffect, useState } from 'react';
import './leaderboardPage.scss';
import { getEmployees } from 'api/userApi';
import LeaderboardCard from '../components/LeaderboardCard/leaderboardCard';
// import authService from '../auth.service';

export default function LeaderboardPage() {
    const [employees, setEmployees] = useState([]);
    const [boolean, setBoolean] = useState(true);
    useEffect(() => {
        async function getEmployeeList() {
            const employeeList = await getEmployees('leader');
            try {
                if (employeeList.status === 500) {
                    setBoolean(false);
                }
                setEmployees(employeeList || []);
            } catch (error) {
                console.log(`Sorry there was an error fetching users: ${error}`);
            }
        }
        getEmployeeList();
    }, []);
    return (
        <div className="LeaderboardPage">
            <div className="LeaderboardHeading">
                <h3 className="LeaderboardTitle">Leaderboard</h3>
            </div>
            {!boolean && (
                <div className="Error">
                    <>
                        <h1 id="ErrorMessage">OHHH NOOO!</h1>
                        <h3 id="ErrorSubText">Something went wrong...</h3>
                    </>
                </div>
            )}
            <div className="LeaderboardGrid">
                {boolean &&
                    employees.map((employee, index) => {
                        return (
                            <LeaderboardCard
                                key={index}
                                profilePic={employee.profilePic}
                                name={employee.username}
                                numH5={employee.h5Count || 0}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
