import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.scss';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import EmployeeIcon from '@material-ui/icons/SupervisorAccount';
import MobileNavIcon from '@material-ui/icons/Dehaze';
import { GoogleLogout } from 'react-google-login';
import Logo from '../logo2/hifive_logo.svg';
import authService from '../auth.service';

export default function NavBar() {
    const imageUrl = authService.getProfileImg();
    const username = authService.getUsername();
    const [notExpanded, setNotExpanded] = useState(true);

    function expandNavbar() {
        setNotExpanded(!notExpanded);
    }

    function logout() {
        localStorage.clear();
    }

    return (
        <div className="NavBarGrid">
            <div className="Expand">
                <button onClick={expandNavbar} className="ExpandButton">
                    <MobileNavIcon className="MobileNavIcon" />
                </button>
            </div>
            <div className="MainNav">
                <div className="Logo">
                    <img className="LogoImage" src={Logo} alt="App Logo" />
                </div>
                <div className="Profile">
                    <NavLink to="/profile" activeClassName="Active">
                        <img className="ProfileImg" src={imageUrl} alt="Profile Pic" />
                        <h5 className="ProfileText">{username} </h5>
                    </NavLink>
                </div>
            </div>

            <div
                className={notExpanded ? 'DesktopNav' : 'MobileNav'}
                role="button"
                tabIndex="0"
                onClick={expandNavbar}
                onKeyDown={expandNavbar}
            >
                <div className="Home">
                    <NavLink to="/home" activeClassName="Active">
                        <HomeIcon className="Icon" />
                        <h5 className="Text">Home </h5>
                    </NavLink>
                </div>

                <div className="Leaderboard">
                    <NavLink to="/leaderboard" activeClassName="Active">
                        <ListIcon className="Icon" />
                        <h5 className="Text">Leaderboard</h5>
                    </NavLink>
                </div>

                <div className="Employees">
                    <NavLink to="/employees" activeClassName="Active">
                        <EmployeeIcon className="Icon" />
                        <h5 className="Text"> Crew </h5>
                    </NavLink>
                </div>

                <div className="SignIn">
                    <NavLink to="/" activeClassName="Active">
                        <GoogleLogout
                            className="Google"
                            clientId="853415921580-09h2cbou2el5gr7s80il478a95kk9ddl.apps.googleusercontent.com"
                            render={renderProps => (
                                <a href="/" onClick={(renderProps.onClick, logout)}>
                                    <LogoutIcon className="Icon" />
                                    <h5 className="Text">Logout</h5>
                                </a>
                            )}
                            onLogoutSuccess={logout}
                        />
                    </NavLink>
                </div>
            </div>
        </div>
    );
}
