import React from 'react';
import './employeeCard.scss';

export default function Members({ name, numH5, profilePic }) {
    return (
        <div className="Card">
            <div className="CardContents">
                <img className="ProfilePic" src={profilePic} alt="profile" />
                <h2 className="Name">{name}</h2>
                <h4 className="H5Num">Hi Fives Received: {numH5}</h4>
            </div>
        </div>
    );
}
