import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { saveH5 } from '../api/Hi5Api';
import TextBox from '../components/Textbox/Textbox';
import SearchInput from '../components/Input/SearchInput';
import authService from '../auth.service';
import './createHi5Page.scss';

function CreateH5() {
    const { register, handleSubmit, control, errors, formState } = useForm({
        mode: 'onChange',
    });

    const history = useHistory();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isProjectOn, setIsProjectOn] = useState(false);
    const [isPersonalOn, setIsPersonalOn] = useState(false);
    const [isCompanyOn, setIsCompanyOn] = useState(false);

    useEffect(() => {
        function disabled() {
            if (formState.touched.recipient && formState.touched.description === true) {
                setIsDisabled(false);
            }
        }
        disabled();
    });

    function colorChange(event) {
        if (event.target.value === 'Personal') {
            setIsPersonalOn(true);
            setIsProjectOn(false);
            setIsCompanyOn(false);
        } else if (event.target.value === 'Project') {
            setIsPersonalOn(false);
            setIsProjectOn(true);
            setIsCompanyOn(false);
        } else if (event.target.value === 'Company') {
            setIsPersonalOn(false);
            setIsProjectOn(false);
            setIsCompanyOn(true);
        }
    }

    function SubmitH5(data) {
        const h5 = {
            recipient: data.recipient.value,
            category: data.category,
            description: data.description,
            date: new Date(),
            profilePic: authService.getProfileImg(),
            username: authService.getUsername(),
            core_value: data.core_value,
        };
        if (h5.recipient === h5.username) {
            history.push('/shame');
        } else {
            saveH5(h5);
            window.location.reload(false);
        }
    }

    function clear() {
        history.push('/home');
    }

    return (
        <div className="Hi5Page">
            <form onSubmit={handleSubmit(SubmitH5)}>
                <h4>Send Your Hi Five</h4>
                <div className="FormStuff">
                    {errors.recipient && <p className="ValidationMsg">Recipient Field Required</p>}
                    <Controller
                        as={SearchInput}
                        name="recipient"
                        control={control}
                        rules={{ required: true }}
                    />
                    <div className="CategoryBtn">
                        <h6>Category:</h6>
                        <input
                            type="button"
                            id={isCompanyOn ? 'projectBtnOn' : 'projectBtn'}
                            value="Company"
                            name="category"
                            onClick={colorChange}
                            ref={register}
                        />
                        <input
                            type="button"
                            id={isProjectOn ? 'projectBtnOn' : 'projectBtn'}
                            value="Project"
                            name="category"
                            onClick={colorChange}
                            ref={register}
                        />
                        <input
                            type="button"
                            id={isPersonalOn ? 'projectBtn3On' : 'projectBtn3'}
                            value="Personal"
                            name="category"
                            onClick={colorChange}
                            ref={register}
                        />
                    </div>
                    <div className="CoreValues">
                        <h6>M2 Core Values:</h6>
                        <input
                            type="radio"
                            id="SupportedAutonomy"
                            name="core_value"
                            value="Supported Autonomy"
                            // onClick={handleChange}
                            ref={register}
                        />
                        <label className="CoreValueBtn" htmlFor="supportedAutonomy">
                            Supported Autonomy
                        </label>
                        <br />
                        <input
                            type="radio"
                            id="HumbleExpertise"
                            name="core_value"
                            value="Humble Expertise"
                            // onClick={handleChange}
                            ref={register}
                        />
                        <label className="CoreValueBtn" htmlFor="HumbleExpertise">
                            Humble Expertise
                        </label>
                        <br />
                        <input
                            type="radio"
                            id="TangibleResults"
                            name="core_value"
                            value="Tangible Results"
                            // onClick={handleChange}
                            ref={register}
                        />
                        <label className="CoreValueBtn" htmlFor="TangibleResults">
                            Tangible Results
                        </label>
                        <br />
                        <input
                            type="radio"
                            id="Success"
                            name="core_value"
                            value="Success is A Team Effort"
                            // onClick={handleChange}
                            ref={register}
                        />
                        <label className="CoreValueBtn" htmlFor="Success">
                            Success is A Team Effort
                        </label>
                    </div>
                    {errors.description && (
                        <p className="ValidationMsg">Description Field Required</p>
                    )}
                    <Controller
                        as={TextBox}
                        name="description"
                        rules={{ required: true }}
                        control={control}
                        placeholder="What's the Hi Five for?"
                    />
                    <div className="Buttons">
                        <button
                            className={isDisabled ? 'SubmitBtn' : 'SubmitBtnEnabled'}
                            type="submit"
                        >
                            <b>Submit Hi Five</b>
                        </button>
                        <button className="ClearBtn" value="Reset" type="reset" onClick={clear}>
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CreateH5;
