import React from 'react';
import './commentCard.scss';

export default function CommentItem(props) {
    const { commentText, profilePic } = props;

    return (
        <div className="CommentItem">
            <img className="ProfileIcon" src={profilePic} alt="profile icon" />
            <h2 className="CommentText">{commentText}</h2>
        </div>
    );
}
