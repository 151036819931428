/* eslint-disable camelcase */
const tokenID = 'h5.auth.token';
const expiration = 'h5.auth.expire';
const email = 'h5.auth.email';
const name = 'h5.auth.name';
const profileImg = 'h5.auth.profileImg';

export default {
    getToken() {
        return JSON.parse(localStorage.getItem(tokenID));
    },
    getTokenExpiration() {
        return JSON.parse(localStorage.getItem(expiration));
    },
    getEmail() {
        return JSON.parse(localStorage.getItem(email)) || 'test@google.com';
    },

    getUsername() {
        return JSON.parse(localStorage.getItem(name)) || 'Test';
    },

    getProfileImg() {
        return JSON.parse(localStorage.getItem(profileImg)) || 'profilePic';
    },
    storeGoogleResponseObject(GoogleObj) {
        localStorage.setItem(tokenID, JSON.stringify(GoogleObj.tokenObj.id_token));
        localStorage.setItem(expiration, JSON.stringify(GoogleObj.tokenObj.expires_in));
        localStorage.setItem(email, JSON.stringify(GoogleObj.profileObj.email));
        localStorage.setItem(name, JSON.stringify(GoogleObj.profileObj.name));
        localStorage.setItem(profileImg, JSON.stringify(GoogleObj.profileObj.imageUrl));
    },
    clearAttempt() {
        localStorage.clear();
    },
    isAuthenticated() {
        const id_token = this.getToken();
        const expires_in = this.getTokenExpiration();

        if (!id_token) {
            return false;
        }
        try {
            if (expires_in === 0) {
                return false;
            }
        } catch (err) {
            return false;
        }
        return true;
    },
};
