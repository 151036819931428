import React, { useState, useEffect } from 'react';

import './commentCard.scss';
import { getH5Comments } from '../../api/Hi5Api';
import CreateCommentForm from './createCommentForm';
import CommentList from './commentList';
// Import get username, profilePic, Need to pass in current ID

export default function CommentCard(props) {
    const { h5ID } = props;
    const [commentList, setCommentList] = useState([]);

    // console.log('cllll' + commentList.id);

    useEffect(() => {
        async function getComments() {
            const list = await getH5Comments(h5ID);

            setCommentList(list);
        }
        getComments();
    }, [h5ID]);

    function refreshCommentList(comment) {
        console.log(comment);
        setCommentList([...commentList, comment]);
    }

    return (
        <div className="CommentCard">
            <CreateCommentForm h5ID={h5ID} onSubmit={refreshCommentList} />
            <CommentList key={h5ID} commentList={commentList} />
        </div>
    );
}
