import Endpoints from './ApiEndpoints';
import { postData } from './Hi5Api';

export async function initializeUserInfo(username, profilePic) {
    const url = `${Endpoints.User}/initialize`;
    try {
        const result = await postData(url, { username, profilePic });
        return result;
    } catch (e) {
        return e;
    }
}

export async function getEmployees(route) {
    let url = `${Endpoints.User}`;
    if (route) {
        url = `${Endpoints.User}/${route}`;
    }

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching Employees',
                error: true,
            };
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return error;
    }
}

export async function EmployeeH5Count(username) {
    const url = `${Endpoints.User}/count/${username}`;

    try {
        const response = await fetch(url);
        if (response.status !== 200) {
            return {
                status: response.status,
                message: 'Error fetching Employees',
                error: true,
            };
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return error;
    }
}
