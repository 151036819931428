import React from 'react';
import './leaderboardCard.scss';

export default function Leaders({ name, numH5, profilePic }) {
    return (
        <div className="LeaderboardCard">
            <div className="LeaderboardCardContents">
                <img className="ProfilePic" src={profilePic} alt="profile" />
                <h2 className="Name">{name}</h2>
                <h4 className="H5Num">Hi Fives: {numH5}</h4>
            </div>
        </div>
    );
}
