import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import './HomePage.scss';
import { useHistory } from 'react-router-dom';
import Hi5PageIcon from '@material-ui/icons/PanTool';
import { Link } from '@material-ui/core';
import Feed from 'components/Feed/feed';
import { getAllH5, getLikedH5 } from 'api/Hi5Api';
import Auth from 'auth.service';

export default function HomePage() {
    const [isClicked1, setIsClicked1] = useState(true);
    const [isClicked2, setIsClicked2] = useState(false);
    const history = useHistory();
    const [h5List, setH5List] = useState([]);
    useEffect(() => {
        async function getList() {
            if (isClicked1) {
                const list = await getAllH5();
                setH5List(list);
            } else if (isClicked2) {
                const list = await getLikedH5(Auth.getUsername());
                setH5List(list);
            }
        }
        getList();
    }, [isClicked2, isClicked1]);

    function handleClick(event) {
        if (event.target.name === 'recent') {
            setIsClicked1(true);
            setIsClicked2(false);
        } else if (event.target.name === 'liked') {
            setIsClicked1(false);
            setIsClicked2(true);
        }
    }

    function mapH5() {
        try {
            h5List.map(listItem => {
                return <Feed key={listItem.id} h5={listItem} />;
            });
        } catch (err) {
            return (
                <>
                    <h1 id="ErrorMessage">OHHH NOOO!</h1>
                    <h3 id="ErrorSubText">Something went wrong...</h3>
                </>
            );
        }
        return h5List.map(listItem => {
            return <Feed key={listItem.id} h5={listItem} />;
        });
    }

    return (
        <div className="HomePage">
            <div className="Heading">
                <h3 className="FeedTitle">Feed</h3>
                <div className="FeedFilterDiv">
                    <button
                        onClick={handleClick}
                        name="recent"
                        className={
                            isClicked1 ? 'FilterBtnClicked FilterBtnRecent' : 'FilterBtnRecent'
                        }
                        type="button"
                    >
                        Recent
                    </button>
                    <button
                        name="liked"
                        onClick={handleClick}
                        className={
                            isClicked2 ? 'FilterBtnClicked FilterBtnLiked' : 'FilterBtnLiked'
                        }
                        type="button"
                    >
                        Liked
                    </button>
                </div>
            </div>
            <div>{mapH5()}</div>
            <div className="Hi5PageLink">
                <Link
                    to="/hi5"
                    onClick={() => {
                        history.push('/hi5');
                    }}
                >
                    <Hi5PageIcon className="Hi5PageIcon" />
                </Link>
            </div>
        </div>
    );
}
