import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import authService from './auth.service';

// https://tylermcginnis.com/react-router-protected-routes-authentication/
const AuthRoutes = ({ component: Component, ...attrs }) => (
    <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attrs}
        render={props =>
            // eslint-disable-next-line react/jsx-props-no-spreading
            authService.isAuthenticated() === true ? <Component {...props} /> : <Redirect to="/" />
        }
    />
);

export default AuthRoutes;
