import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import './ProfilePage.scss';
// import authService from '../auth.service';
import Feed from 'components/Feed/feed';
import { getReceivedH5, getSentH5 } from 'api/Hi5Api';
import Auth from 'auth.service';

export default function ProfilePage() {
    const [isClicked1, setIsClicked1] = useState(true);
    const [isClicked2, setIsClicked2] = useState(false);
    const [h5List, setH5List] = useState([]);
    const username = Auth.getUsername();

    useEffect(() => {
        async function getList() {
            if (isClicked1) {
                const list = await getReceivedH5(username);
                setH5List(list);
            } else if (isClicked2) {
                const list = await getSentH5(username);
                setH5List(list);
            }
        }
        getList();
    }, [username, isClicked2, isClicked1]);

    function handleClick(event) {
        if (event.target.name === 'received') {
            setIsClicked1(true);
            setIsClicked2(false);
        } else if (event.target.name === 'sent') {
            setIsClicked1(false);
            setIsClicked2(true);
        }
    }

    function mapH5() {
        try {
            h5List.map(listItem => {
                return <Feed key={listItem.id} h5={listItem} />;
            });
        } catch (err) {
            return (
                <>
                    <h1 id="ErrorMessage">OHHH NOOO!</h1>
                    <h3 id="ErrorSubText">Something went wrong...</h3>
                </>
            );
        }
        return h5List.map(listItem => {
            return <Feed key={listItem.id} h5={listItem} />;
        });
    }

    return (
        <div className="ProfilePage">
            <div className="Heading">
                <h3 className="FeedTitle">Profile</h3>
                <div className="FeedFilterDiv">
                    <button
                        onClick={handleClick}
                        name="received"
                        className={
                            isClicked1 ? 'FilterBtnClicked FilterBtnReceived' : 'FilterBtnReceived'
                        }
                        type="button"
                    >
                        Received
                    </button>
                    <button
                        name="sent"
                        onClick={handleClick}
                        className={isClicked2 ? 'FilterBtnClicked FilterBtnSent' : 'FilterBtnSent'}
                        type="button"
                    >
                        Sent
                    </button>
                </div>
            </div>
            <div>{mapH5()}</div>
        </div>
    );
}
