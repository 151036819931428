import React from 'react';
import { Route } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';

import HomePage from './home/HomePage';
import SignInPage from './signin/SignInPage';
import EmployeePage from './EmployeePage/employeePage';
import CreateHi5Page from './createHi5/CreateHi5Page';
import LeaderboardPage from './LeaderboardPage/leaderboardPage';
import ProfilePage from './profile/ProfilePage';
import GifPage from './gif/gifPage';

export default function routes() {
    return (
        <div className="routes">
            <Route exact path="/" component={SignInPage} />
            <AuthRoutes exact path="/home" component={HomePage} />
            <AuthRoutes exact path="/profile" component={ProfilePage} />
            <AuthRoutes exact path="/employees" component={EmployeePage} />
            <AuthRoutes exact path="/hi5" component={CreateHi5Page} />
            <AuthRoutes exact path="/leaderboard" component={LeaderboardPage} />
            <AuthRoutes exact path="/shame" component={GifPage} />
        </div>
    );
}
