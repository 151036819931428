import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { initializeUserInfo } from 'api/userApi';
import authService from '../auth.service';
import Logo from '../logo2/hifive_logo.svg';
import './SignInPage.scss';

function SignInPage() {
    const history = useHistory();
    const [login, setLogin] = useState(false);

    const responseGoogle = response => {
        // set google JWT as auth_token cookie for server validation
        // document.cookie = `auth_token=${response.getAuthResponse().id_token}`;
        // document.cookie = `expires=${new Date(
        //     response.getAuthResponse().expires_at
        // ).toGMTString()}`;

        // save to local storage
        authService.storeGoogleResponseObject(response);
        // initialize user with DB
        initializeUserInfo(response.profileObj.name, response.profileObj.imageUrl);
        history.push('/home');
    };

    const loginFail = () => {
        authService.clearAttempt();
        history.push('/');
        setLogin(true);
    };

    return (
        <div className="SignInPage">
            <div className="SignInBackgroundGrid">
                <div className="Logo">
                    <img className="LogoImg" src={Logo} alt="" />
                </div>
                <h1>Welcome to Hi5</h1>
                {login && <h3>Login Failed. Please Try Again</h3>}
                <GoogleLogin
                    className="GoogleBtn"
                    clientId="853415921580-09h2cbou2el5gr7s80il478a95kk9ddl.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={loginFail}
                    cookiePolicy="single_host_origin"
                />
            </div>
        </div>
    );
}
export default SignInPage;
