import React from 'react';

import './commentCard.scss';

import CommentItem from './commentItem';
// Import get username, profilePic, Need to pass in current ID

export default function CommentList(props) {
    const { commentList } = props;

    return (
        <div className="CommentList">
            {commentList.map(comment => {
                return (
                    <CommentItem
                        key={comment.id}
                        profilePic={comment.profilePic}
                        commentText={comment.comment}
                    />
                );
            })}
        </div>
    );
}
