import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getEmployees } from 'api/userApi';
import './SearchInput.scss';

const formatGroupLabel = data => (
    <div className="GroupStyles">
        <span>{data.label}</span>
        <span className="GroupBadgeStyles">{data.options.length}</span>
    </div>
);

export default function SearchInput({ name, onChange }) {
    const [mileTwoNames, setMileTwoNames] = useState([{ value: null, label: '@name' }]);
    const [selectedName, setSelectedName] = useState('');
    useEffect(() => {
        async function getUsers() {
            const list = await getEmployees();
            try {
                const employeeArray = list.map(employee => {
                    return { value: employee.username, label: employee.username };
                });
                setMileTwoNames(employeeArray);
            } catch (e) {
                console.log(`Sorry there was an error fetching users: ${e}`);
            }
        }
        getUsers();
    }, []);
    function handleSelectChange(selectedOption) {
        setSelectedName(selectedOption);
        onChange(selectedOption);
    }
    return (
        <Select
            className="Search"
            defaultValue={mileTwoNames[0]}
            options={mileTwoNames}
            formatGroupLabel={formatGroupLabel}
            onChange={handleSelectChange}
            value={selectedName}
            name={name}
        />
    );
}
