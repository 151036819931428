import React, { useState, useEffect } from 'react';
import './employeePage.scss';
import { getEmployees } from 'api/userApi';
import EmployeeCard from '../components/EmployeeCard/employeeCard';

export default function EmployeePage() {
    const [employees, setEmployees] = useState([]);
    const [boolean, setBoolean] = useState(true);

    useEffect(() => {
        async function getEmployeeList() {
            const employeeNames = await getEmployees('employee');
            try {
                employeeNames.sort((a, b) =>
                    a.username.split(' ')[1] > b.username.split(' ')[1] ? 1 : -1
                );
                setEmployees(employeeNames || []);
            } catch (e) {
                setBoolean(false);
                console.log(`Sorry there was an error fetching users: ${e}`);
            }
        }
        getEmployeeList();
    }, []);

    return (
        <div className="EmployeePage">
            <div className="Heading">
                <h3 className="Title">Employees</h3>
            </div>
            {!boolean && (
                <div className="Error">
                    <>
                        <h1 id="ErrorMessage">OHHH NOOO!</h1>
                        <h3 id="ErrorSubText">Something went wrong...</h3>
                    </>
                </div>
            )}
            <div className="EmployeeGrid">
                {employees.map((employee, index) => {
                    return (
                        <EmployeeCard
                            key={index}
                            profilePic={employee.profilePic}
                            name={employee.username}
                            numH5={employee.h5Count || 0}
                        />
                    );
                })}
            </div>
        </div>
    );
}
