import React, { useState, useEffect } from 'react';
import OpenHeart from '@material-ui/icons/FavoriteBorder';
import SolidHeart from '@material-ui/icons/Favorite';
import './feed.scss';
import { addLike } from 'api/Hi5Api';
import Auth from 'auth.service';
import CommentCard from '../CommentCard/commentCard';

export default function Feed(props) {
    const { h5 = {} } = props;
    const [count, setCount] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        function likeCountSetter() {
            if (h5.liked_by != null) {
                setCount(Object.keys(h5.liked_by).length);
                // eslint-disable-next-line no-prototype-builtins
                if (h5.liked_by.hasOwnProperty(Auth.getUsername())) {
                    setIsClicked(true);
                }
            }
        }

        likeCountSetter();
    }, [h5.liked_by]);

    function increaseLike() {
        // eslint-disable-next-line no-unused-expressions
        isClicked ? setIsClicked(false) : setIsClicked(true);
        addLike(h5.id, Auth.getUsername()).then(result => {
            setCount(Object.keys(result).length);
        });
    }

    function getFormattedDate() {
        let formatedDate = new Date(h5.date);
        formatedDate = formatedDate.toDateString();
        return formatedDate;
    }

    function showCommentsOnClick() {
        setShowComments(!showComments);
    }

    return (
        <>
            {h5 && (
                <div className="Feed">
                    <div className="Row1">
                        <img className="ProfileIcon" src={h5.profilePic} alt="profile icon" />
                        <h3 className="UserName">{h5.username}</h3>
                        <p className="Date">{getFormattedDate()}</p>
                    </div>
                    <div className="Row2">
                        <p className="RecipientMessage">
                            I want to give <b>@{h5.recipient}</b> a Hi Five. {h5.description}
                        </p>
                    </div>
                    <div className="Likes">
                        <button
                            data-testid="likes"
                            disabled={isClicked}
                            onClick={increaseLike}
                            className="Heart"
                        >
                            {isClicked ? (
                                <SolidHeart style={{ fill: '#DA1C5C' }} fontSize="small" />
                            ) : (
                                <OpenHeart fontSize="small" />
                            )}
                        </button>
                        <p>{count}</p>

                        <h5 className="CategoryType">{h5.core_value}</h5>
                        <h5 className="CategoryType">{h5.category}</h5>
                    </div>
                    <div className="Comments">
                        <button
                            data-testid="comments"
                            className="Comment"
                            onClick={showCommentsOnClick}
                        >
                            Comments
                        </button>
                        {showComments && (
                            <div>
                                <CommentCard h5ID={h5.id} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
